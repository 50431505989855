import TagManager from "react-gtm-module";
import { I18nextProvider } from "react-i18next";
import { createRoot } from "react-dom/client";
import { App } from "./app";
import { ZendeskInit } from "./zendesk";
import Configuration, { configPromise } from "core/configuration";
import i18next from "core/i18n";
import { store, persistor, sagaMiddleware } from "core/store";
import { rootSaga } from "store";

initHotJar();
initCookiebot();

void (async () => {
    await configPromise;

    TagManager.initialize({ gtmId: Configuration.getTagManagerId() });

    if (import.meta.env?.MODE === "mock") {
        const { mockServer } = await import("./mirage/mockServer");
        mockServer();
    }

    const appContainer = document.querySelector("#root")!;
    const appRoot = createRoot(appContainer);
    const zendeskContainer = document.querySelector("#zendesk")!;
    const zendeskRoot = createRoot(zendeskContainer);

    sagaMiddleware.run(rootSaga);

    appRoot.render(<App store={store} persistor={persistor} />);
    zendeskRoot.render(<I18nextProvider i18n={i18next}><ZendeskInit store={store} /></I18nextProvider>);
})();

function initHotJar() {
    if (location.host.startsWith("localhost")) {
        return;
    }

    const tag = document.createElement("script");
    tag.src = "hotjar.js";

    document.head.prepend(tag);
}

function initCookiebot() {
    if (location.host.startsWith("localhost")) {
        return;
    }

    const tag = document.createElement("script");
    tag.id = "Cookiebot";
    tag.dataset.cbid = "361bdd62-36a6-4f16-8ef7-0f60b177b750";
    tag.dataset.blockingmode = "auto";
    tag.src = "https://consent.cookiebot.com/uc.js";

    document.head.prepend(tag);
}
